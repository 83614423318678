<template>
  <div class="page">
    <div class="list">
      <van-cell-group>
        <van-cell title="头像"  value="修改" is-link @click="changeAvatar"/>
        <van-cell title="名字"  value="豹子" is-link @click="changeName"/>
        <van-cell title="ID号" value="3995" />
        <van-cell title="手机号码" value="已绑定 13366721017" />
      </van-cell-group>
    </div>
  
    <van-popup
        v-model="showPop"
        closeable
        position="bottom"
        @close="onClose"
    >
      <div class="change-box">
        <div class="title f32b">修改名字</div>
        <div class="name">兵马俑</div>
        <textarea style="height: 100px; border: none;" placeholder="限制4-20个字符，可由中英文、数字组成" placeholder-style="color: #777777;"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    name: 'myself',
    data() {
      return {
        showPop: false,
      }
    },
    methods: {
      /**
       * 更换头像
       * TODO: 调用云闪付端能力
       */
      changeAvatar() {
      },
      changeName() {
        this.showPop = true
      },
      onClose(){
        this.showPop = false
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    height: 100%;
    background-color: #F5F5F5;
  }

  .list {
    padding: 24px;
    border-radius: 6px;
  }

  .change-box {
    padding: 32px;
    height: 600px;
  }
  .change-box .title {
    text-align: center;
  }
  .change-box .name {
    margin-bottom: 20px;
    padding: 20px 0;
    font-size: 28px;
    border-bottom: 1px solid #EEEEEE;
  }

  .van-cell-group {
    padding: 15px;
  }

  .van-cell {
    padding-left: 0!important;
    font-size: 35px;
    line-height: 60px;
    align-items: center;
  
    .van-cell__left-icon, .van-cell__right-icon {
      font-size: 30px;
    }
  }
</style>
